/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BASE_MODULES } from '../context/plano/usePlano'
import { adicionaModuloCasos } from '../utils/casos'

export const useCasos = (
  assinaturaAtual,
  novaAssinatura,
  setNovaAssinatura,
  precos,
  AnalyticsService,
  tipoMudanca
) => {
  const [moduloDeCasos, setModuloDeCasos] = useState(null)
  const [searchParams, _setSearchParams] = useSearchParams()

  const setupCasosIniciais = () => {
    const { precos: precoAssinaturaAtual } = assinaturaAtual
    const { tiposDeModulo: modulosUsuario } = assinaturaAtual.assinatura
    const { tabelaDePrecoModulos } = precoAssinaturaAtual
    const { precosModulos } = tabelaDePrecoModulos
    const modulosContratados = [
      ...new Set([...modulosUsuario, ...BASE_MODULES])
    ]
    let moduloCasosExistente = modulosContratados.find(e => e.includes('CASOS'))
    moduloCasosExistente =
      moduloCasosExistente === 'CASOS_ILIMITADO'
        ? 'CASOS_7'
        : moduloCasosExistente
    setQuantidadeCasosIniciais(moduloCasosExistente, precosModulos)
    setupNovoModuloCaso(modulosUsuario, precosModulos)
  }

  const setQuantidadeCasosIniciais = (casoTipo, precosModulos) => {
    if (!precosModulos) return
    const moduloDeCasos = precosModulos?.find(e => e.tipo === casoTipo)
    return setModuloDeCasos(moduloDeCasos?.tipo)
  }

  const handlerSelectCasos = moduloCasosSelecionado => {
    setModuloDeCasos(moduloCasosSelecionado)
    let assinaturaAtualizada = novaAssinatura
    const { tabelaDePrecoModulos } = precos

    assinaturaAtualizada = adicionaModuloCasos(
      tabelaDePrecoModulos,
      moduloCasosSelecionado,
      novaAssinatura
    )

    const existeCaso = novaAssinatura.tiposDeModulo.some(modulo =>
      modulo.includes('CASOS')
    )
    const existeCasosAtualizada = assinaturaAtualizada.tiposDeModulo.some(
      modulo => modulo.includes('CASOS')
    )
    if (existeCaso && !existeCasosAtualizada)
      AnalyticsService.trackEvent(
        `Removeu um módulo em ${tipoMudanca?.toLowerCase()} assinatura`
      )
    if (!existeCaso && existeCasosAtualizada)
      AnalyticsService.trackEvent(
        `Adicionou um módulo em ${tipoMudanca?.toLowerCase()} assinatura`
      )
    setNovaAssinatura(assinaturaAtualizada)
  }

  const setupNovoModuloCaso = (tiposDeModulo, precosModulos) => {
    const moduloQuery = searchParams?.get('modulo')?.toUpperCase()
    if (moduloQuery !== 'CASOS') return
    const modulosAtual = tiposDeModulo
    const modulosCasosDisponiveis = precosModulos.filter(e =>
      e.tipo.includes('CASOS')
    )
    const moduloCasosAtual = modulosAtual.find(e => e.includes('CASOS'))
    if (!moduloCasosAtual) setQuantidadeCasosIniciais('CASOS_1', precosModulos)
    if (moduloCasosAtual) {
      const quantidadeCasosAtual = modulosCasosDisponiveis.find(
        e => e.tipo === moduloCasosAtual
      ).quantidadeMinimaUsos
      const novoModuloCasos = modulosCasosDisponiveis.find(
        e => e.quantidadeMinimaUsos > quantidadeCasosAtual
      )
      if (novoModuloCasos)
        setQuantidadeCasosIniciais(novoModuloCasos.tipo, precosModulos)
    }
  }

  return {
    moduloDeCasos,
    setModuloDeCasos,
    handlerSelectCasos,
    setupCasosIniciais
  }
}
