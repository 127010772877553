import {
  Button,
  Card,
  PrevPlatformContext,
  SegmentContext,
  Text,
  Select
} from '@prev/ui-kit'
import { Col, Row, Slider } from 'antd'
import {
  FileSearchOutlined,
  BuildOutlined,
  RightOutlined,
  AuditOutlined
} from '@ant-design/icons'
import { useContext } from 'react'
import 'antd/dist/antd.css'
import { betweenTwoValues } from '../../utils/prevModules'
import { PlanoContext } from '../../context/plano/PlanoContext'
import useWindowSize from '../../hooks/useWindowSize'

const Modulos = () => {
  const {
    setNovaAssinatura,
    novaAssinatura,
    setStep,
    precos,
    handlerSelectCasos,
    handlerSelectLaudos,
    moduloDeLaudos,
    moduloDeCasos,
    tipoMudanca,
    AnalyticsService
  } = useContext(PlanoContext)
  const { theme } = useContext(PrevPlatformContext)
  const { analytics } = useContext(SegmentContext)

  if (!precos) return null

  const casosAtual = precos.tabelaDePrecoModulos.precosModulos.find(
    e => e.tipo === novaAssinatura.tiposDeModulo.find(e => e.includes('CASOS'))
  )
  const laudosAtual = precos.tabelaDePrecoModulos.precosModulos.find(
    e => e.tipo === novaAssinatura.tiposDeModulo.find(e => e.includes('LAUDOS'))
  )

  const possivelCaso = precos.tabelaDePrecoModulos.precosModulos.find(
    e =>
      e.tipo.includes('CASOS') &&
      e.tipo !== 'CASOS_ILIMITADO' &&
      e.tipo === moduloDeCasos
  )

  const possivelLaudo = precos.tabelaDePrecoModulos.precosModulos.find(
    e => e.tipo.includes('LAUDOS') && e.tipo === moduloDeLaudos
  )

  const handleMudancaModulo = modulo => {
    const removeuModuloString = `Removeu um módulo em ${tipoMudanca?.toLowerCase()} assinatura`
    const adicionouModuloString = `Adicionou um módulo em ${tipoMudanca?.toLowerCase()} assinatura`
    if (novaAssinatura.tiposDeModulo.includes(modulo))
      AnalyticsService.trackEvent(removeuModuloString)
    if (!novaAssinatura.tiposDeModulo.includes(modulo))
      AnalyticsService.trackEvent(adicionouModuloString)
    handleAddModule(modulo)
  }

  const handleAddModule = module => {
    if (novaAssinatura.tiposDeModulo.includes(module)) {
      return setNovaAssinatura({
        ...novaAssinatura,
        tiposDeModulo: novaAssinatura.tiposDeModulo.filter(m => m !== module)
      })
    }
    setNovaAssinatura({
      ...novaAssinatura,
      tiposDeModulo: [...novaAssinatura.tiposDeModulo, module]
    })
  }

  const laudosPadraoSelect = [
    {
      value: null,
      label: `Selecionar quantidade de laudos`
    }
  ].concat(
    precos.tabelaDePrecoModulos.precosModulos
      .filter(e => e.tipo.includes('LAUDOS'))
      .map(modulo => {
        return {
          value: modulo.tipo,
          label: `Até ${modulo.quantidadeMaximoUsos} laudos/mês`
        }
      })
  )

  const casosOptions = [
    {
      value: null,
      label: `Selecionar quantidade de casos`
    }
  ].concat(
    precos.tabelaDePrecoModulos.precosModulos
      .filter(e => e.tipo.includes('CASOS') && e.tipo !== 'CASOS_ILIMITADO')
      .map(modulo => {
        return {
          value: modulo.tipo,
          label: `Até ${modulo.quantidadeMaximoUsos} casos/mês`
        }
      })
  )

  return (
    <Col>
      <Row>
        <Text emphasys style={{ flex: 'auto' }}>
          Módulos disponíveis
        </Text>
        <Button
          id="button_prosseguir"
          data-testid="button_prosseguir"
          aria-label="Avançar"
          size="large"
          onClick={() => setStep(2)}
        >
          Avançar
        </Button>
      </Row>
      <Row
        type="flex"
        justify="space-between"
        style={{
          rowGap: '20px',
          columnGap: '20px',
          width: '100%'
        }}
      >
        <Row
          type="flex"
          justify="space-between"
          style={{
            columnGap: '20px',
            width: '100%'
          }}
        >
          <Col xs={24} sm={24} md={24} lg={10} xl={10} span={10}>
            <Card
              style={{
                width: '100%',
                backgroundColor: '#F2F8FE',
                minHeight: '220px'
              }}
              color="#F2F8FE"
            >
              <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: '15px' }}
                gutter={[0, 16]}
              >
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    rowGap: '10px',
                    columnGap: '10px'
                  }}
                >
                  <FileSearchOutlined
                    style={{
                      fontSize: '32px',
                      color: theme.color.brand.secondary
                    }}
                  />
                  <Text emphasys>Diretório de advogados</Text>
                </Col>
                <Button
                  style={{
                    backgroundColor: novaAssinatura.tiposDeModulo.find(
                      e => e === 'CATALOGO'
                    )
                      ? theme.color.neutral[100]
                      : theme.color.brand.secondary,
                    borderColor: novaAssinatura.tiposDeModulo.find(
                      e => e === 'CATALOGO'
                    )
                      ? theme.color.functional.error
                      : theme.color.brand.secondary,
                    color: novaAssinatura.tiposDeModulo.find(
                      e => e === 'CATALOGO'
                    )
                      ? theme.color.functional.error
                      : theme.color.neutral[100],
                    minWidth: '100px'
                  }}
                  id="modulos_diretorio_adicionar"
                  aria-label="Adicionar módulo diretório de advogados"
                  data-testid="modulos_diretorio_adicionar"
                  onClick={() => handleMudancaModulo('CATALOGO')}
                >
                  {novaAssinatura.tiposDeModulo.find(e => e === 'CATALOGO')
                    ? 'Remover'
                    : 'Adicionar'}
                </Button>
              </Row>
              <Row type="flex">
                <Text color={theme.color.neutral[600]}>
                  Apareça no Diretório de Advogados do Previdenciarista e
                  divulgue seu escritório para as mais de 650 mil visitas de
                  clientes em potencial que recebemos todos os meses.
                </Text>
              </Row>
              <Row type="flex" style={{ alignItems: 'center' }}>
                <Text
                  style={{ margin: '40px 0 10px 0', cursor: 'pointer' }}
                  color={theme.color.brand.secondary}
                  onClick={() => {
                    window.open(
                      'https://previdenciarista.com/diretorio-de-advogados',
                      '_blank'
                    )
                  }}
                >
                  <RightOutlined /> Saber mais
                </Text>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={13} xl={13} span={13}>
            <Card
              style={{
                width: '100%',
                backgroundColor: '#F2F8FE',
                minHeight: '220px'
              }}
              color="#F2F8FE"
            >
              <Row
                type="flex"
                justify="space-between"
                style={{
                  marginBottom: '15px'
                }}
                gutter={[0, 16]}
              >
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    rowGap: '10px',
                    columnGap: '10px'
                  }}
                >
                  <AuditOutlined
                    style={{
                      fontSize: '32px',
                      color: theme.color.brand.secondary
                    }}
                  />
                  <Text emphasys>Banco de Laudos</Text>
                </Col>
                <Button
                  color="brand-secondary"
                  id="modulos_laudos_adicionar"
                  aria-label="Adicionar módulo Banco de Laudos"
                  data-testid="modulos_laudos_adicionar"
                  style={{
                    backgroundColor: laudosAtual
                      ? theme.color.neutral[100]
                      : theme.color.brand.secondary,
                    borderColor: laudosAtual
                      ? theme.color.functional.error
                      : theme.color.brand.secondary,
                    color: laudosAtual
                      ? theme.color.functional.error
                      : theme.color.neutral[100],
                    minWidth: '100px'
                  }}
                  onClick={() => {
                    if (laudosAtual) return handlerSelectLaudos(null)
                    handlerSelectLaudos('LAUDOS_1')
                  }}
                >
                  {laudosAtual ? 'Remover' : 'Adicionar'}
                </Button>
              </Row>
              <Row type="flex" align="middle">
                <Text color={theme.color.neutral[600]}>
                  Pesquise facilmente em segundos nosso acervo de{' '}
                  <strong>mais de 8,5 mil laudos periciais paradigma</strong> de
                  empresas que realizam alguma forma de atividade especial.
                </Text>
              </Row>
              <Row type="flex" align="middle">
                <Text
                  size="base"
                  color={theme.color.neutral[1000]}
                  emphasys
                  style={{ margin: '10px 0' }}
                >
                  Selecione o número de laudos que deseja baixar no mês
                </Text>
                <Row
                  type="flex"
                  style={{
                    width: '100%',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                  gutter={[16, 16]}
                >
                  <Col xs={24} sm={24} md={24} lg={16} xl={16} span={16}>
                    <Select
                      id="select_laudos"
                      data-testid="select_laudos"
                      defaultValue={
                        laudosAtual
                          ? laudosAtual.tipo
                          : laudosPadraoSelect[0].value
                      }
                      value={
                        laudosAtual
                          ? laudosAtual.tipo
                          : laudosPadraoSelect[0].value
                      }
                      onChange={value => {
                        handlerSelectLaudos(value)
                      }}
                      options={laudosPadraoSelect}
                    ></Select>
                  </Col>
                  <Col xs={8} sm={5} md={5} lg={8} xl={8} span={8}>
                    <Text>
                      <Text
                        type="span"
                        size="base"
                        color={theme.color.neutral[1000]}
                        emphasys
                      >
                        {possivelLaudo?.valorBase
                          ? Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(possivelLaudo.valorBase)
                          : 'R$ 0,00'}
                      </Text>
                      <Text
                        type="span"
                        size="sm"
                        color={theme.color.neutral[600]}
                      >
                        /{novaAssinatura.intervalo === 'MENSAL' ? 'mês' : 'ano'}
                      </Text>
                    </Text>
                  </Col>
                </Row>
                <Row type="flex" style={{ alignItems: 'center' }}>
                  <Text
                    style={{ margin: '40px 0 10px 0', cursor: 'pointer' }}
                    color={theme.color.brand.secondary}
                    onClick={() => {
                      window.open(
                        'https://previdenciarista.com/banco-de-laudos-produto/',
                        '_blank'
                      )
                    }}
                  >
                    <RightOutlined /> Conheça o Banco de Laudos
                  </Text>
                </Row>
              </Row>
            </Card>
          </Col>
        </Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
          <Card
            style={{
              width: '100%',
              backgroundColor: '#F2F8FE',
              minHeight: '220px'
            }}
            color="#F2F8FE"
          >
            <Row
              type="flex"
              justify="space-between"
              style={{
                marginBottom: '15px'
              }}
              gutter={[0, 16]}
            >
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  rowGap: '10px',
                  columnGap: '10px'
                }}
              >
                <BuildOutlined
                  style={{
                    fontSize: '32px',
                    color: theme.color.brand.secondary
                  }}
                />
                <Text emphasys>PrevCasos</Text>
              </Col>
              <Button
                color="brand-secondary"
                id="modulos_prevcasos_adicionar"
                aria-label="Adicionar módulo Prev Casos"
                data-testid="modulos_prevcasos_adicionar"
                style={{
                  backgroundColor: casosAtual
                    ? theme.color.neutral[100]
                    : theme.color.brand.secondary,
                  borderColor: casosAtual
                    ? theme.color.functional.error
                    : theme.color.brand.secondary,
                  color: casosAtual
                    ? theme.color.functional.error
                    : theme.color.neutral[100],
                  minWidth: '100px'
                }}
                onClick={() => {
                  if (casosAtual) return handlerSelectCasos(null)
                  handlerSelectCasos('CASOS_1')
                }}
              >
                {casosAtual ? 'Remover' : 'Adicionar'}
              </Button>
            </Row>
            <Row type="flex" align="middle">
              <Text color={theme.color.neutral[600]}>
                Receba{' '}
                <strong>
                  milhares de casos previdenciários de clientes em potencial
                </strong>{' '}
                enviados todos os dias por pessoas no Brasil inteiro e fale
                diretamente com o segurado.
              </Text>
            </Row>
            <Row type="flex" align="middle">
              <Text
                size="base"
                color={theme.color.neutral[1000]}
                emphasys
                style={{
                  margin: '10px 0',
                  pointerEvents: 'none'
                }}
              >
                Selecione o número de casos que deseja contratar no mês
              </Text>
              <Row
                type="flex"
                style={{
                  width: '100%',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
                gutter={[16, 16]}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={10}
                  xl={10}
                  span={10}
                  data-testid="slider"
                >
                  <Select
                    id="select_casos"
                    data-testid="select_casos"
                    defaultValue={
                      casosAtual ? casosAtual.tipo : casosOptions[0].value
                    }
                    value={casosAtual ? casosAtual.tipo : casosOptions[0].value}
                    onChange={value => {
                      handlerSelectCasos(value)
                    }}
                    options={casosOptions}
                    width="fit-content"
                  />
                </Col>
                <Col xs={8} sm={5} md={5} lg={8} xl={8} span={8}>
                  <Text>
                    <Text
                      type="span"
                      size="base"
                      color={theme.color.neutral[1000]}
                      emphasys
                    >
                      {possivelCaso?.valorBase
                        ? Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(possivelCaso.valorBase)
                        : 'R$ 0,00'}
                    </Text>
                    <Text
                      type="span"
                      size="sm"
                      color={theme.color.neutral[600]}
                    >
                      /{novaAssinatura.intervalo === 'MENSAL' ? 'mês' : 'ano'}
                    </Text>
                  </Text>
                </Col>
              </Row>
              <Row type="flex" style={{ alignItems: 'center' }}>
                <Text
                  style={{
                    margin: '40px 0 10px 0',
                    cursor: 'pointer'
                  }}
                  color={theme.color.brand.secondary}
                  onClick={() => {
                    window.open(
                      'https://previdenciarista.com/casos-previdenciarios/',
                      '_blank'
                    )
                  }}
                >
                  <RightOutlined /> Conheça o PrevCasos
                </Text>
              </Row>
            </Row>
          </Card>
        </Col>
      </Row>
      <Button
        id="button_prosseguir"
        data-testid="button_prosseguir"
        aria-label="Avançar"
        style={{ margin: '20px 0' }}
        size="large"
        onClick={() => setStep(2)}
      >
        Avançar
      </Button>
      <Row
        type="flex"
        style={{
          flexDirection: 'column',
          rowGap: '20px',
          columnGap: '10px',
          marginBottom: '100px'
        }}
      >
        {novaAssinatura?.intervalo === 'MENSAL' ? (
          <>
            <Text emphasys>
              Quais as vantagens de trocar o meu plano mensal para o anual?
            </Text>
            <Text color="#949595">
              Migrando para o plano anual você economiza 20% no pagamento da
              assinatura. Você também garante o "congelamento" do preço pela
              duração do plano anual. Nenhum reajuste é aplicado durante o ciclo
              atual do plano atual. Independente disso, você também pode
              cancelar a qualquer momento para impedir que o plano seja renovado
              no futuro, se desejar.
            </Text>
          </>
        ) : (
          <>
            <Text emphasys>O que é a "Data da próxima cobrança"?</Text>
            <Text color="#949595">
              É a data em que seu plano será renovado e cobrado futuramente caso
              você não cancele sua assinatura. Porém você pode cancelar sua
              assinatura a qualquer momento para evitar renovações futuras em
              até 03 cliques dentro da sua própria plataforma. Veja como é fácil
              cancelar{' '}
              <a href="https://ajuda.previdenciarista.com/article/151-como-cancelar-minha-assinatura">
                clicando aqui
              </a>
              .
            </Text>
          </>
        )}
        {novaAssinatura?.tiposDeModulo.length < 2 && (
          <>
            <Text emphasys>Turbine sua assinatura com módulos extras!</Text>
            <Text color="#949595">
              Você pode complementar sua assinatura com outros serviços extras
              do Previdenciarista. Confira os módulos acima e turbine sua
              assinatura para adquirir mais clientes e facilitar a rotina do seu
              escritório.
            </Text>
          </>
        )}
        {precos && precos.proRata && (
          <>
            <Text emphasys>O que é o desconto pró-rata?</Text>
            <Text color="#949595">
              No pró-rata calculamos o período que você já utilizou de um plano
              ou módulo, caso opte por alterar sua assinatura durante um período
              vigente e já pago anteriormente. Dessa forma você recebe o período
              não utilizado na forma de desconto.
            </Text>
            <Text emphasys>
              Qual a diferença entre "Valor a ser cobrado agora" e "Valor da
              próxima cobrança"?
            </Text>
            <Text color="#949595">
              O "Valor a ser cobrado agora" é o valor que cobraremos nesse
              momento na sua assinatura, podendo ser o valor integral de uma
              assinatura caso você não esteja dentro de um período pago, ou
              valores pró-rata referente a mudanças na assinatura como
              acréscimos de módulos no período atual. O "Valor da próxima
              cobrança" se refere ao valor dos próximos períodos, quando sua
              assinatura se renovar automaticamente no futuro. Esse seria o
              "valor cheio" do seu plano, sem descontos pró-rata aplicados.
            </Text>
          </>
        )}
      </Row>
    </Col>
  )
}

export default Modulos
