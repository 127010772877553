import { Card, Radio, Text, Ajuda } from '@prev/ui-kit'
import { Col, Row } from 'antd'
import React, { useContext } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'
import useWindowSize from '../../hooks/useWindowSize'
import { findModule, prevModules } from '../../utils/prevModules'
import { formataDataJSX } from '../../utils/formataDatas'
import { PlanoContext } from '../../context/plano/PlanoContext'
import { BASE_MODULES } from '../../context/plano/usePlano'
import Swal from 'sweetalert2'
import { ajudasText } from '../../utils/ajudasTexto.js'

const modulos = {
  CALCULOS_PETICOES: 'calculos',
  CATALOGO: 'diretorio',
  CASOS: 'casos',
  LAUDOS: 'laudos'
}

function ResumoNovoPlano({ carregandoPreco }) {
  const { width } = useWindowSize()
  const {
    novaAssinatura,
    precos,
    setNovaAssinatura,
    assinaturaAtual,
    setModuloDeCasos,
    setModuloDeLaudos
  } = useContext(PlanoContext)

  const regraMostrarModalConfirmaPagto =
    novaAssinatura.intervalo === 'ANUAL' &&
    precos &&
    (precos.proRata ||
      moment(precos.assinatura.periodo.dataDaCobranca).isSameOrBefore(
        new Date()
      ))

  const proximaDatacobranca = () => {
    if (assinaturaAtual.assinatura.trial && precos.proRata) {
      const { periodoDeAcesso } = precos.proRata
      const fimAcesso = moment(periodoDeAcesso.fim)
        .add(1, 'day')
        .format('YYYY-MM-DD')
      const diaCobrancaFormatada = formataDataJSX(fimAcesso)
      return diaCobrancaFormatada
    }
    const dataFormatada = formataDataJSX(
      precos.assinatura.dataDaProximaCobranca
    )
    return dataFormatada
  }

  if (!precos) return null
  return (
    <Card
      style={{
        margin: '20px 0 0px 0',
        marginBottom: width < 1024 ? '20px' : 0,
        opacity: carregandoPreco ? 0 : 1
      }}
      data-testid="sidebar_resumo_plano_futuro"
    >
      <Row style={{ marginBottom: '10px' }} align="middle">
        <Radio
          checked={novaAssinatura.intervalo === 'MENSAL'}
          id="sidebar_escolha_mensal"
          data-testid="sidebar_escolha_mensal"
          aria-label="Escolher Plano mensal"
          onClick={() => {
            if (novaAssinatura.intervalo !== 'MENSAL')
              setNovaAssinatura({ ...novaAssinatura, intervalo: 'MENSAL' })
          }}
          textEmphasys
        >
          Mensal
        </Radio>
        <Radio
          checked={novaAssinatura.intervalo === 'ANUAL'}
          textEmphasys
          id="sidebar_escolha_anual"
          data-testid="sidebar_escolha_anual"
          aria-label="Escolher Plano anual"
          onClick={() => {
            if (novaAssinatura.intervalo !== 'ANUAL') {
              return Swal.fire({
                title: 'Tem certeza?',
                html: 'Você confirma a alteração para o plano anual ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3575AB',
                cancelButtonColor: '#d33',
                confirmButtonText:
                  "<span data-testid='modal_confirmacao_sim'>Sim</span>",
                cancelButtonText:
                  "<span data-testid='modal_confirmacao_nao'>Não</span>"
              }).then(result => {
                if (result.value)
                  setNovaAssinatura({ ...novaAssinatura, intervalo: 'ANUAL' })
              })
            }
          }}
        >
          Anual
        </Radio>
        {/* {precos.valorDoDesconto + (precos.proRata ? precos.proRata.valorDoDesconto : 0) > 0 ? ( */}

        {/* ) : null} */}
      </Row>
      <Row style={{ marginBottom: '20px' }} align="middle">
        <Text
          emphasys
          style={{
            backgroundColor: '#EDF6FD',
            padding: '5px',
            color: '#3575AB',
            borderRadius: '10px'
          }}
        >
          Economize 20% e parcele em até 6x no anual
          {/* {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(precos.valorDoDesconto + (precos.proRata ? precos.proRata.valorDoDesconto : 0))} */}
        </Text>
      </Row>
      <Col>
        <Text style={{ marginBottom: '10px', display: 'flex' }} emphasys>
          Resumo da alteração
          <Ajuda
            itens={ajudasText}
            page={'resumoNovoPlano'}
            element={'planoFuturo'}
            propsPopover={{ autoAdjustOverflow: true }}
          />
        </Text>
        <Text emphasys style={{ marginBottom: '5px' }}>
          Plano
        </Text>
        <Row type="flex" justify="space-between">
          <Text
            style={{ marginBottom: '5px' }}
            color="#949595"
            id="futuro_plano"
            data-testid="futuro_plano"
          >
            {novaAssinatura.intervalo}
          </Text>
          <Text id="futuro_valor_bruto" data-testid="futuro_valor_bruto">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(
              findModule(
                'CALCULO_PETICOES',
                precos.tabelaDePrecoModulos.precosModulos
              ).valorBase
            )}
          </Text>
        </Row>
        {novaAssinatura.tiposDeModulo.filter(e => e !== 'CALCULO_PETICOES')
          .length ? (
          <Text emphasys style={{ marginBottom: '10px' }}>
            Módulos
          </Text>
        ) : null}
        <Col span={24}>
          {novaAssinatura.tiposDeModulo
            .filter(modulo => modulo !== 'CALCULO_PETICOES')
            .map((e, i) => (
              <Row
                key={`${i}-${e}`}
                type="flex"
                justify="space-between"
                align="middle"
                style={{
                  marginBottom: '10px',
                  rowGap: '10px',
                  columnGap: '10px'
                }}
              >
                <Row style={{ columnGap: '10px', rowGap: '10px' }}>
                  {prevModules(e)}
                </Row>
                <Row style={{ columnGap: '10px', rowGap: '10px' }}>
                  {!BASE_MODULES.includes(e) && (
                    <DeleteOutlined
                      style={{
                        fontSize: '20px',
                        cursor: 'pointer',
                        color: '#F26526'
                      }}
                      onClick={() => {
                        if (e.includes('CASOS')) setModuloDeCasos(0)
                        if (e.includes('LAUDOS')) setModuloDeLaudos(null)
                        setNovaAssinatura({
                          ...novaAssinatura,
                          tiposDeModulo: novaAssinatura.tiposDeModulo.filter(
                            modulo => !modulo.includes(e)
                          )
                        })
                      }}
                    />
                  )}
                  <Text
                    data-testid={`futuro_modulo_${
                      e.includes('CASOS')
                        ? 'casos'
                        : e.includes('LAUDOS')
                        ? 'laudos'
                        : modulos[e]
                    }_valor`}
                  >
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(
                      findModule(e, precos.tabelaDePrecoModulos.precosModulos)
                        .valorBase
                    )}
                  </Text>
                </Row>
              </Row>
            ))}
        </Col>
        {precos.valorDoDesconto ||
        (precos.proRata && precos.proRata.valorDoDesconto) ? (
          <>
            <Text emphasys style={{ marginBottom: '5px' }}>
              Descontos
            </Text>
            {precos && precos.valorDoDesconto && !precos.proRata ? (
              <Row type="flex" justify="space-between">
                <Text style={{ marginBottom: '5px' }} color="#949595">
                  Desconto de 20%
                </Text>
                <Text
                  style={{ marginBottom: '5px' }}
                  color="#949595"
                  id="futuro_valor_desconto"
                  data-testid="futuro_valor_desconto"
                >
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(precos.valorDoDesconto)}
                </Text>
              </Row>
            ) : null}
            {precos.proRata && precos.proRata.valorDoDesconto > 0 && (
              <Row type="flex" justify="space-between">
                <Text
                  data-testid="pro-rata"
                  style={{ marginBottom: '5px', display: 'flex' }}
                  color="#949595"
                >
                  Pró-rata
                  <Ajuda
                    itens={ajudasText}
                    page={'resumoNovoPlano'}
                    element={'proRata'}
                    propsPopover={{ autoAdjustOverflow: true }}
                  />
                </Text>
                <Text
                  style={{ marginBottom: '5px' }}
                  color="#949595"
                  id="futuro_desconto_prorata"
                  data-testid="futuro_desconto_prorata"
                >
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(precos.proRata.valorDoDesconto)}
                </Text>
              </Row>
            )}
          </>
        ) : (
          ''
        )}

        {!precos.proRata ? (
          <Row type="flex" justify="space-between">
            <Text emphasys style={{ marginBottom: '5px' }}>
              {regraMostrarModalConfirmaPagto ||
              assinaturaAtual.assinatura.trial
                ? 'Total a ser cobrado agora'
                : 'Total'}
            </Text>
            <Text
              color="#949595"
              id="futuro_valor_liquido"
              data-testid="futuro_valor_liquido"
            >
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(precos.valor)}{' '}
              / {precos.assinatura.intervalo === 'ANUAL' ? 'Ano' : 'Mês'}
            </Text>

            <Row style={{ marginTop: 20, marginBottom: '10px' }}>
              <Text color="#949595">
                Data da próxima cobrança:{' '}
                <span
                  id="futuro_data_proxima_cobranca"
                  data-testid="futuro_data_proxima_cobranca"
                >
                  {assinaturaAtual.assinatura.trial &&
                  precos.assinatura.periodo.dataFimTrial
                    ? formataDataJSX(precos.assinatura.periodo.dataDaCobranca)
                    : formataDataJSX(precos.assinatura.dataDaProximaCobranca)}
                </span>
              </Text>
            </Row>
          </Row>
        ) : (
          <>
            <Row
              type="flex"
              justify="space-between"
              style={{ marginBottom: '5px' }}
            >
              <Text emphasys style={{ display: 'flex' }}>
                Valor a ser cobrado agora
                <Ajuda
                  itens={ajudasText}
                  page={'resumoNovoPlano'}
                  element={'cobradoAgora'}
                  propsPopover={{ autoAdjustOverflow: true }}
                />
              </Text>
              <Text
                color="#949595"
                id="futuro_valor_liquido_agora"
                data-testid="futuro_valor_liquido_agora"
              >
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(precos.proRata.valor)}
              </Text>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Text color="#949595">
                De{' '}
                <span data-testid="futuro_prorata_periodo_inicio">
                  {formataDataJSX(precos.proRata.periodoDeAcesso.inicio)}
                </span>{' '}
                até{' '}
                <span data-testid="futuro_prorata_periodo_fim">
                  {' '}
                  {formataDataJSX(precos.proRata.periodoDeAcesso.fim)}
                </span>
              </Text>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              style={{ marginBottom: '5px' }}
            >
              <Text emphasys style={{ display: 'flex' }}>
                {precos.assinatura.intervalo === 'ANUAL'
                  ? 'Valor da próxima cobrança'
                  : 'Valor nos próximos meses'}
                <Ajuda
                  itens={ajudasText}
                  page={'resumoNovoPlano'}
                  element={'proximaCobranca'}
                  propsPopover={{ autoAdjustOverflow: true }}
                />
              </Text>
              <Text
                color="#949595"
                id="futuro_valor_liquido"
                data-testid="futuro_valor_liquido"
              >
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(precos.valor)}{' '}
                / {precos.assinatura.intervalo === 'ANUAL' ? 'Ano' : 'Mês'}
              </Text>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Text color="#949595">
                Data da próxima cobrança:
                <span
                  id="futuro_data_proxima_cobranca"
                  data-testid="futuro_data_proxima_cobranca"
                >
                  {' '}
                  {proximaDatacobranca()}
                </span>
              </Text>
            </Row>
          </>
        )}
      </Col>
    </Card>
  )
}

export default ResumoNovoPlano
