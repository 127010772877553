/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAnalytics } from '../../hooks/useAnalytics'
import { useAssinatura } from '../../hooks/useAssinatura'
import { useCasos } from '../../hooks/useCasos'
import { useLaudos } from '../../hooks/useLaudos'
import { usePagamento } from '../../hooks/usePagamento'

export const BASE_MODULES = ['CALCULO_PETICOES']

const usePlano = () => {
  const [step, setStep] = useState(0)
  const [tipoMudanca, setTipoMudanca] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  // Services used in this hook

  const {
    assinaturaAtual,
    setNovaAssinatura,
    novaAssinatura,
    setupNovaAssinatura
  } = useAssinatura()

  const AnalyticsService = useAnalytics(assinaturaAtual)

  useEffect(() => {
    const url = window.location.href?.toUpperCase()
    handleStepInicial()
    if (url.includes('ALTERAR')) return setTipoMudanca('ALTERAR')
    setTipoMudanca('REATIVAR')
  }, [])

  const handleStepInicial = () => {
    const step = searchParams.get('step')
    if (step) setStep(Number(step))
  }

  const {
    pagamento,
    sucessoPix,
    carregandoPreco,
    precos,
    meioDePagamento,
    sucessoPagamento,
    erroPagamento,
    informacoesTrial,
    setInformacoesTrial,
    postAlterarPlano,
    postReativarPlano,
    setMeioDePagamento,
    resetaInformacoesPagamento,
    setSucessoPagamento,
    setSucessoPix,
    setErroPagamento,
    setPagamento,
    numeroDeParcelas,
    setNumeroDeParcelas
  } = usePagamento(novaAssinatura, AnalyticsService, assinaturaAtual)

  const {
    moduloDeCasos,
    setModuloDeCasos,
    handlerSelectCasos,
    casesMinMax,
    setupCasosIniciais
  } = useCasos(
    assinaturaAtual,
    novaAssinatura,
    setNovaAssinatura,
    precos,
    AnalyticsService,
    tipoMudanca
  )

  const {
    moduloDeLaudos,
    setModuloDeLaudos,
    handlerSelectLaudos,
    laudosMinMax,
    setupLaudosIniciais
  } = useLaudos(
    assinaturaAtual,
    novaAssinatura,
    setNovaAssinatura,
    precos,
    AnalyticsService,
    tipoMudanca
  )

  useEffect(() => {
    if (pagamento && step !== 2) resetaInformacoesPagamento()
    if (step !== 3) setNumeroDeParcelas(1)
  }, [step, pagamento])

  useEffect(() => {
    const { precos } = assinaturaAtual || {}
    if (precos) setupCasosIniciais(precos)
    if (precos) setupLaudosIniciais(precos)
  }, [assinaturaAtual])

  useEffect(() => {
    const { precos } = assinaturaAtual || {}
    if (assinaturaAtual && precos) setupNovaAssinatura(precos)
  }, [assinaturaAtual])

  return {
    // usePagamento
    pagamento,
    sucessoPix,
    carregandoPreco,
    precos,
    meioDePagamento,
    sucessoPagamento,
    erroPagamento,
    informacoesTrial,
    numeroDeParcelas,
    setNumeroDeParcelas,
    setInformacoesTrial,
    postAlterarPlano,
    postReativarPlano,
    setMeioDePagamento,
    resetaInformacoesPagamento,
    setSucessoPagamento,
    setSucessoPix,
    setErroPagamento,
    setPagamento,
    // useCasos
    moduloDeLaudos,
    moduloDeCasos,
    setModuloDeCasos,
    casesMinMax,
    laudosMinMax,
    setModuloDeLaudos,
    handlerSelectCasos,
    handlerSelectLaudos,
    // useAlterarPlano
    step,
    assinaturaAtual,
    novaAssinatura,
    setStep,
    setNovaAssinatura,
    tipoMudanca,
    // useAnalytics
    AnalyticsService
  }
}

export default usePlano
